<template>
  <section>
    <navi-bar>
      {{$t('front.mypage.moveMoney')}}
    </navi-bar>
    <div class="moneytab">
      <a :class="{'on': selectType === 'give'}" @click="selectType = 'give'"><span>{{$t('front.mypage.moveMoney')}}</span></a>
      <a :class="{'on': selectType === 'list'}" @click="selectType = 'list'"><span>{{$t('front.mypage.moveMoneyList')}}</span></a>
    </div>
    <div v-if="selectType === 'give'" class="mb100">
      <div class="fx">
         <div>
            <h4 class="giveName">* {{$t('front.give.bottomPatner')}}</h4>
            <div class="appliwrite data">
              <!--input type="text" class="numb" name="id" list="id" :placeholder="$t('front.give.bottomPatner')" v-model="selectMemId">
              <datalist id="id" >
                <template v-for="member in memList" v-bind:key="member.memId">
                  <option :value="member.memId">{{member.memNick}}</option>
                </template>
              </datalist-->
              <select v-model="selectPartnerCode">
                <option value="" disabled selected>{{$t('front.give.bottomPatner')}}</option>
                <template v-for="item in partnerLevelList" v-bind:key="item.code">
                  <option :value="item.code">{{item.codeName}}</option>
                </template>
              </select>
            </div>
         </div>
         <div>
            <h4 class="giveName">* {{$t('front.give.resultIDs')}}</h4>
            <div class="appliwrite data">
              <!--input type="text" class="numb" name="id" list="id" :placeholder="$t('front.give.resultIDs')" v-model="selectMemId">
              <datalist id="id" >
                <template v-for="member in memList" v-bind:key="member.memId">
                  <option :value="member.memId">{{member.memNick}}</option>
                </template>
              </datalist-->
              <select v-model="selectMem">
                <option value="" disabled selected>{{$t('front.give.resultIDs')}}</option>
                <template v-for="member in myMemIdsList" v-bind:key="member.memId">
                  <option :value="member">{{member.memNick}}</option>
                </template>
              </select>
            </div>
         </div>
      </div>
      <h4 class="giveName">* {{$t('front.give.targetMoney')}}</h4>
      <div class="appliwrite"><input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.give.targetMoney')" v-model="selectMem.cashAmt"/></div>
      <h4 class="giveName">* {{$t('front.give.moneyInputs')}}</h4>
      <div class="appliwrite"><input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.give.moneyInput')" v-model="cashAmt"/><img src="@/assets_mobile/img/icon_delete.svg" @click="cashAmt = ''"/></div>
      <ul class="applimoneybtn">
        <li><a @click="setMoney(10000)">+1{{$t('front.cash.man')}}P</a></li>
        <li><a @click="setMoney(50000)">+5{{$t('front.cash.man')}}P</a></li>
        <li><a @click="setMoney(100000)">+10{{$t('front.cash.man')}}P</a></li>
        <li><a @click="setMoney(1000000)">+100{{$t('front.cash.man')}}P</a></li>
      </ul>

      <div class="btnwrap">
        <a class="canceal" @click="memCash('-3')">{{$t('front.give.give')}}</a>
        <a class="ok" @click="memCash('3')">{{$t('front.give.back')}}</a>
      </div>
    </div><!-- 회원머니지급 -->

    <div v-if="selectType === 'list'" class="mb100" >
      <div class="writebtn mt10 mb10">
        <select v-model="searchForm.cashStatus" class="select">
          <option :value="''">{{$t('front.gameCategory.all')}}</option>
          <option :value="'1'">{{$t('front.give.ok')}}</option>
          <option :value="'-1'">{{$t('front.gameCategory.all')}}</option>
        </select>
<!--        <select class="select">-->
<!--          <option value="">처리성공여부</option>-->
<!--          <option value="">전체</option>-->
<!--          <option value="">성공</option>-->
<!--          <option value="">실패</option>-->
<!--        </select>-->
        <select v-model="searchForm.cashType" class="select">
          <option :value="''">{{$t('front.gameCategory.all')}}</option>
          <option :value="'in'">{{$t('front.give.give')}}</option>
          <option :value="'out'">{{$t('front.give.back')}}</option>
        </select>
      </div>
      <date-filter-mobile :retail="true" @search="loadData" />
      <div class="gamebetwrap">
        <template v-if="list.length > 0">
          <template v-for="(item) in list" v-bind:key="item.cashIdx">
            <div class="gamebetlist">
              <ul class="gamebetdetail textleft">
                <li class="ft13" v-if="item.cashStatus === '1'">{{$t('front.board.complet')}}({{dateFormatAll(item.regDate)}})</li>
                <li class="ft13" v-else><em class="rd">{{$t('front.member.fail')}}</em>{{item.memo}}({{dateFormatAll(item.regDate)}})</li>

                <li class="ft15 mb10" v-if="item.cashType === '3'"><em class="icongive">{{$t('front.give.give')}}</em>{{item.botMemId}}</li>
                <li class="ft15 mb10" v-else><em class="iconback">{{$t('front.give.back')}}</em>{{item.botMemId}}</li>

                <li class="ft15">{{$t('front.give.sendMoney')}}</li>
                <li class="ft13 gr">{{$t('front.give.endCash')}}</li>
                <li class="ft13 gr">{{$t('front.give.haveCash')}}</li>
              </ul>
              <ul class="gamebetdetail textright">
                <li class="mt60 ft15 bl" v-if="item.cashType === '3'"><span class="fb bl">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>
                <li class="mt60 ft15 rd" v-else><span class="rd fb">{{thousand(item.cashAmt)}}</span> {{$t('front.common.money')}}</li>

                <li class="ft13 gr">{{thousand(item.aftCashAmt)}} {{$t('front.common.money')}}</li>
                <li class="ft13 gr">{{thousand(item.preCashAmt)}} {{$t('front.common.money')}}</li>
              </ul>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="gamebetlist">
            {{$t('front.common.notFoundList')}}
          </div>
        </template>
      </div>
    </div><!-- 회원머니지급내역 -->

    <pagination-mobile :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList"/>

  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Give from '@/views/member/mypage/give'

export default {
  name: 'give.vue',
  mixins: [
    Give
  ],
  components: {
    DateFilterMobile,
    NaviBar,
    PaginationMobile
  },
  data () {
    return {
      selectType: 'give',
      memberinfo: false
    }
  },
  methods: {
    setMoney (value) {
      this.cashAmt = (Number(this.cashAmt) + value).toString()
    }
  }
}
</script>
<style scoped>
.writebtn {justify-content: space-between !important;}
.writebtn .select {margin-right: 0 !important;}
.giveName {font-size: 13px;padding: 42px 25px 5px;color: #fff;}
.icongive {font-size: 9px;color: #fff;padding: 3px 6px;border-radius: 4px;background: #1d51c9;margin-right: 5px;}
.iconback {font-size: 9px;color: #fff;padding: 3px 6px;border-radius: 4px;background: #e1423c;margin-right: 5px;}
.gselect {padding: 0;border: 0;width: 100%;}
.fx {display: flex;gap: 10px;margin: 0 25px;}
.fx > div {width: 100%;}
.fx .appliwrite {margin: 0;}
.fx .giveName {padding: 42px 0 5px;}
.data select {background: #141414;color: #fff;border: 0;width: 100%;height: 35px;}
select option[value=""][disabled] {
   display: none;
}
</style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/betlist.css"></style>
